import React from 'react';
import { useOrdersState } from "../../../state/index";

interface Item {
    MTRL: string;
    VATVAL: string;
    CODE1: string;
    QTY1: string;
    PRET: string;
    LINENUM: string;
    NUME: string;
}

interface SelectedOrder {
    BRANCH: {
        CUSBRANCH: string;
    };
    ITELINES: Item[];
}

const View: React.FC = () => {
    const { selectedOrder, judete } = useOrdersState();

    const typedSelectedOrder = selectedOrder as SelectedOrder;

    return (
        <div>
            <div className="order-info">
                <h2>Comanda #</h2>
                <h3>{selectedOrder.SALDOC.FINDOC}</h3>
                <span> / </span>
                <h3>{selectedOrder.SALDOC.CCCFINCODE1}</h3>
            </div>
            <div className="customer-data">
                <div className="address-data">
                    <h3>Billing address</h3>
                    <p>
                        <label>SoftOne ID: </label>
                        <span>{typedSelectedOrder?.BILLING?.TRDBRANCH}</span>
                    </p>
                    <p>
                        <label>Name: </label>
                        <span>{typedSelectedOrder?.BILLING?.NAME}</span>
                    </p>
                    <p>
                        <label>District: </label>
                        <span>{judete[typedSelectedOrder?.BILLING?.REGION] ?? 'N/A'}</span>
                    </p>
                    <p>
                        <label>City: </label>
                        <span>{typedSelectedOrder?.BILLING?.CITY}</span>
                    </p>
                    <p>
                        <label>Address: </label>
                        <span>{typedSelectedOrder?.BILLING?.ADDRESS}</span>
                    </p>
                    <p>
                        <label>Phone: </label>
                        <span>{typedSelectedOrder?.BILLING?.PHONE1}</span>
                    </p>
                    <p>
                        <label>Email: </label>
                        <span>{typedSelectedOrder?.BILLING?.EMAIL}</span>
                    </p>
                </div>
                <div className="address-data">
                    <h3>Shipping address</h3>
                    <p>
                        <label>SoftOne ID: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.TRDBRANCH}</span>
                    </p>
                    <p>
                        <label>Name: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.NAME}</span>
                    </p>
                    <p>
                        <label>District: </label>
                        <span>{judete[typedSelectedOrder?.SHIPPING?.REGION] ?? 'N/A'}</span>
                    </p>
                    <p>
                        <label>City: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.CITY}</span>
                    </p>
                    <p>
                        <label>Address: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.ADDRESS}</span>
                    </p>
                    <p>
                        <label>Phone: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.PHONE1}</span>
                    </p>
                    <p>
                        <label>Email: </label>
                        <span>{typedSelectedOrder?.SHIPPING?.EMAIL}</span>
                    </p>
                </div>
                <div className="address-data">
                    <h3>Customer</h3>
                    <p>
                        <label>SoftOne ID: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.TRDR}</span>
                    </p>
                    <p>
                        <label>Name: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.NUME}</span>
                    </p>
                    <p>
                        <label>District: </label>
                        <span>{judete[typedSelectedOrder?.CUSTOMER?.REGION] ?? 'N/A'}</span>
                    </p>
                    <p>
                        <label>City: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.CITY}</span>
                    </p>
                    <p>
                        <label>Address: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.ADDRESS}</span>
                    </p>
                    <p>
                        <label>Phone: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.PHONE1}</span>
                    </p>
                    <p>
                        <label>Email: </label>
                        <span>{typedSelectedOrder?.CUSTOMER?.EMAIL}</span>
                    </p>
                </div>
            </div>
            <h3>ITEM LINES</h3>
                <table className="data-grid">
                    <thead>
                        <tr>
                            <th className="items-data data-grid-th">SKU</th>
                            <th className="items-data data-grid-th">Name</th>
                            <th className="items-data data-grid-th">QTY</th>
                            <th className="items-data data-grid-th">PRICE</th>
                            <th className="items-data data-grid-th">VAT</th>
                        </tr>
                    </thead>
                    {typedSelectedOrder.ITELINES.map((item, index) => (
                    <tbody>
                        <tr>
                            <td>
                                <span>{item.CODE1}</span>
                            </td>
                            <td>
                                <span>{item.NUME}</span>
                            </td>
                            <td>
                                <span>{item.QTY1}</span>
                            </td>
                            <td>
                                <span>{item.PRET}</span>
                            </td>
                            <td>
                                <span>{item.VATVAL}</span>
                            </td>
                        </tr>
                    </tbody>
                    ))}
                </table>
        </div>
    );
};

export default View;
