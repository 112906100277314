import React, { useEffect,  ChangeEvent } from 'react';
import { useTable, usePagination } from 'react-table';
import { useOrdersState } from "../../../state/index";

interface OrdersTableProps {
    columns: any;
}

const OrdersTable: React.FC<OrdersTableProps> = ({ columns }) => {

    const {
        filterSoftOneId,
        setFilterSoftOneId,
        searchIncrementId,
        handleSearchIncrementId,
        currentPage,
        handlePageChange,
        data,
        handleEndDateChange,
        handleStartDateChange,
        filterStartDate,
        filterEndDate,
        handleFetchOrdersClick
    } = useOrdersState();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: currentPage - 1, pageSize: 10 },
        },
        usePagination
    );

    useEffect(() => {
        gotoPage(currentPage - 1);
    }, [currentPage, gotoPage]);

    const handleSoftOneIdFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilterSoftOneId(e.target.value);
    };

    const handleIncrementIdSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSearchIncrementId(e.target.value);
    };

    return (
        <div>
            <div className="order-filter">
                <label>Filtru dupa ERP ID: </label>
                <input
                    type="text"
                    value={filterSoftOneId}
                    onChange={handleSoftOneIdFilterChange}
                />
                <label>Filtru dupa ID comanda: </label>
                <input
                    type="text"
                    value={searchIncrementId}
                    onChange={handleIncrementIdSearchChange}
                />
            </div>
            <table {...getTableProps()} className="frontend-data-grid" style={{ border: 'solid 1px black' }}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                className="view-data data-grid-th"
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                            padding: '10px',
                                            border: 'solid 1px gray',
                                            background: '#ffffff',
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="order-filter">
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={!canPreviousPage}>
                    Pagina precedenta
                </button>
                <span> Page {currentPage} of {pageCount}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={!canNextPage}>
                    Pagina urmatoare
                </button>
            </div>
        </div>
    );
};

export default OrdersTable;
