import React from 'react';
import {useOrdersState} from '../../state';
import View from "./OrderDetails/View";

interface OrderDetailsProps {
    onBack: () => void;
    order: any;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({onBack}) => {
    const {handleCancelOrder, selectedOrder, isLoading} = useOrdersState();

    const shouldDisplayCancelButton =
        selectedOrder &&
        (selectedOrder.SALDOC.STATUSNAMELIVRARE === 'Pending' ||
            selectedOrder.SALDOC.STATUSNAMELIVRARE === 'Pending Payment' ||
            selectedOrder.SALDOC.STATUSNAMELIVRARE === 'Hold');

    const handleCancelAndRedirect = async () => {
        await handleCancelOrder();
        window.location.href = `${window.location.origin}/softone_sales_orders/order/orderhistory`;
    };

    return (
        <div className="order-view-buttons">
            <View/>
            <button className="action-secondary"
                    style={{margin: '10px'}}
                    onClick={onBack}>
                Back to Table
            </button>
            {shouldDisplayCancelButton && (
                <button className="action-secondary"
                        style={{margin: '10px'}}
                        onClick={handleCancelAndRedirect}
                        disabled={isLoading}>
                    {isLoading ? 'Cancelling...' : 'Cancel Order'}
                </button>
            )}
        </div>
    );
};

export default OrderDetails;
